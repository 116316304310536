//
// Section
//

/*
  <div class="section section--sidebar-left">
    <div class="section__inner grid">
      <div class="section__sidebar grid__col">
        ...
      </div>
      <div class="section__content grid__col">
        ...
      </div>
    </div><!-- ./grid -->
  </div><!-- ./section -->
*/

.section {
  margin-top: $line-space-2x;
  position: relative;

  @include breakpoint(lg) {
    margin-top: $line-space-2-5x;
  }

  .block + &,
  .section + & {
    @include breakpoint(lg) {
      margin-top: $line-space-4x;
    }
  }

  // Containerize section width

  &:not(.section--full):not(.grid) {
    @include make-container();
  }

  // Nested in an outer block

  .block & {
    max-width: none;
  }

  // Reset margins

  > .block,
  > .section__inner > .block {
    margin-top: 0;
    margin-bottom: 0;
  }

  .section__inner.grid {
    margin-top: 0;
  }

  .section__content {
    margin-top: 0;
  }

  .section__content > .block:first-child:not(.grid) {
    margin-top: 0;
  }

  .section__content .grid__col {
    > *:last-child {
      margin-bottom: 0;
    }
  }

  // With rule above

  &.is-divided,
  &.is-divided-stealth {
    padding-top: $line-space;

    &.is-divided::before {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      border-top: 1px solid $gray-rule;
      max-width: 1280px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    @include breakpoint(lg) {
      padding-top: rem(48);
    }
  }

  // Section header

  .block--section-head {
    margin-bottom: $line-space;

    @include breakpoint(lg) {
      margin-bottom: $line-space-2x;

      &.is-section-subhead {
        margin-bottom: $line-space;
      }
    }

    > * {
      margin-bottom: 0;
    }
  }

  // Action links

  .block--action {
    margin-top: $line-space;

    @include breakpoint(lg) {
      margin-top: $line-space-2x;
    }
  }

  // Sidebars

  .section__sidebar {
    margin-top: 0;

    h2 + p,
    .h2 + p {
      margin-top: rem(12);
    }
  }

  &.is-sticky-sidebar {
    .snapshot {
      position: sticky;
      top: rem(24);
    }
  }
  &.section--sidebar-left,
  &.section--sidebar-right {
    .section__sidebar {
      margin-bottom: $line-space;

      > *:last-child {
        margin-bottom: 0;
      }
    }

    @include breakpoint(lg) {
      // Default 4-8 split

      .section__sidebar {
        $width: percentage(4/12);
        flex-basis: $width;
        width: $width;
        max-width: $width;
        display: flex;
        flex-direction: column;
      }

      .section__content {
        $width: percentage(8/12);
        flex-basis: $width;
        width: $width;
        max-width: $width;
      }

      // Sidebar variations

      .section__sidebar.is-sidebar-small {
        $width: percentage(3/12);
        flex-basis: $width;
        width: $width;
        max-width: $width;
        margin-bottom: 0;
      }

      .section__sidebar.is-sidebar-narrow {
        $width: percentage(2/12);
        flex-basis: $width;
        width: $width;
        max-width: $width;
      }

      // Content variations

      .section__content.is-content-big {
        $width: percentage(9/12);
        flex-basis: $width;
        width: $width;
        max-width: $width;
      }

      .section__content.is-content-wide {
        $width: percentage(10/12);
        flex-basis: $width;
        width: $width;
        max-width: $width;
      }

      // Offset by one column
      .section__content.is-content-offset {
        $width: percentage(1/12);
        margin-left: $width;
      }
    }
  }

  &.section--sidebar-right {
    @include breakpoint(lg) {
      .section__sidebar {
        order: 2;
      }

      .section__content {
        order: 1;
      }

      // Offset by one column
      .section__content.is-sidebar-offset {
        $width: percentage(1/12);
        margin-left: $width;
      }
    }

    &.is-mobile-sidebar-first {
      @include breakpoint-down(lg) {
        > .section__inner {
          flex-direction: column-reverse;
        }
        .section__sidebar {
          margin-top: 0;
          margin-bottom: $line-space-2x;
        }
      }
    }
  }

  // Mobile sidebar below content

  @include breakpoint-down(lg) {
    &.section--sidebar-right .section__sidebar,
    .section__sidebar.is-mobile-last {
      margin-top: $line-space-2x;
      margin-bottom: 0;
      order: 9999;
    }
  }

  // Adjustments based on context

  .tabs__pane > &:first-child {
    margin-top: $line-space;

    @include breakpoint(lg) {
      margin-top: $line-space-2x;
    }
  }
}
