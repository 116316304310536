//
// Page header
//

.page-header {
  padding-top: $line-space-2x;
  margin-bottom: $line-space-2x;
  @include make-container();

  @include breakpoint(lg) {
    padding-top: $line-space-8x;
    margin-bottom: $line-space-4x;
  }

  .page-header__inner {
    max-width: $text-max-width;
  }

  &.page-header--wide {
    .page-header__inner {
      max-width: $text-max-width-wide;
    }
  }

  &.page-header--full {
    .page-header__inner {
      max-width: 100%;
    }
  }

  &.is-top {
    @include breakpoint(lg) {
      margin-bottom: $line-space-2x;
    }
  }

  .is-eyebrow {
    display: block;
    margin: -26px 0 rem(8);
  }

  h1 {
    margin: 0;
  }

  h1 + .is-byline {
    margin-top: rem(16);
  }

  h1 + .is-lede {
    margin-top: rem(23);
  }

  > *:last-child {
    margin-bottom: 0;
  }
}
