//
// Section head block
//

.block--section-head {

  // With rule above

  &.is-divided {
    padding-top: $line-space;
    border-top: 1px solid $gray-rule;

    @include breakpoint(lg) {
      padding-top: rem(48);
    }
  }

  // Common styles

  .block__inner {
    max-width: $text-max-width-small;
  }

  h2 + p, .h2 + p {
    margin-top: rem(12);
  }
}
