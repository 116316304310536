//
// News card a grid
//

.card.card--news {
  display: block;
  border: 0;
  color: inherit;
  border-top: 1px solid $gray-rule;
  padding-top: rem(12);

  *:last-child {
    margin-bottom: 0;
  }

  .card__body {
    padding: 0;
    display: flex;
    flex-direction: column;

    .h3 {
      transition: $transition-link;
      margin-bottom: rem(6);
    }

    .is-eyebrow + .h3 {
      margin-top: rem(12);
    }

  }

  .card__footer {
    margin-top: auto;
  }

  // When the card is a link

  &:hover, &:active {
    .card__body {
      .h3 {
        color: $red;

      }
    }
  }

  // Remove top rule on first row for mobile

  .grid__col:first-child & {
    border-top: 0;
    padding-top: 0;
  }

  // Add border after first row

  @include breakpoint-only(md, lg) {
    .grid--2up-md > .grid__col:nth-child(-n+2) & {
      border-top: 0;
      padding-top: 0;
    }
  }

  @include breakpoint(lg) {
    .grid--3up-lg > .grid__col:nth-child(-n+3) & {
      border-top: 0;
      padding-top: 0;
    }

    .grid--2up-lg > .grid__col:nth-child(-n+2) & {
      border-top: 0;
      padding-top: 0;
    }
  }
}


