//
// Variables
//


//
// Colors
//

$red: #EA002C;
$orange: #FF7A00;
$purple: #68127A; // rgb 104 18 122
$purple-light: rgba(104,18,122,0.1);
$teal: #009A93; // rgb 0 154 147
$teal-light: rgba(0,154,147,0.1);
$blue: #0072C6; // rgb 0 114 198
$blue-light: rgba(0,114,198,0.1);
$green: #007A16; // rgb 0 122 22
$green-light: rgba(0,122,22,0.1);

$red-text-sm: #e0002a; // small text contrast

$white: #FFFFFF;
$black: #111111;

$gray-light: #F4F4F4;
$gray-medium: #707070;
$gray-dark: #444444;

$gray-rule: #C7C9C7;
$gray-border: #B0B0B0;

$text-color: $gray-dark;
$text-black: $black;
$text-dark: $gray-dark;
$text-muted: $gray-medium;


//
// Typography
//

$font-weight-light: 300; // Open Sans Light
$font-weight-normal: 400; // Open Sans Regular
$font-weight-semibold: 600; // Open Sans Semi-bold
$font-weight-bold: 700; // Open Sans Bold
$font-weight-heavy: 800; // Open Sans Heavy

$font-family-base: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$font-size-base: 1rem; // 16px
$line-height-base: (24/16); // 1.5
$line-height-tight: (20/16); // 1.25

$font-size-larger: 1.5rem; // 24px
$font-size-large: 1.25rem; // 20px
$font-size-small: 0.875rem; // 14px

$heading-size-1: 2.5rem; // 40px
$heading-size-2: 1.6875rem; // 36px
$heading-size-3: 1.125rem; // 18px
$heading-size-4: 1rem; // 16px
$heading-size-5: 1rem; // 16px
$heading-size-6: 1rem; // 16px

//
// Transitions
//

$transition-base: all 0.6s ease;
$transition-btn: all 0.3s ease;
$transition-link: all 0.3s ease;


//
// Grid breakpoints
//

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1280px
);


// Grid containers
//
// Outer padding

$container-padding-xs: 20px;
$container-padding-md: 40px;
$container-padding-lg: 80px;

$container-max-width: 1280px;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 19px;
$grid-gutter-width-md: 40px;
$grid-gutter-width-lg: 40px;

// Base line spacing
$baseline-grid-unit: .25rem; // 4px

$line-space: $baseline-grid-unit * 6; // 24px
$line-space-1-5x: $line-space * 1.5; // 36px
$line-space-2x: $line-space * 2; // 48px
$line-space-2-5x: $line-space * 2; // 60px
$line-space-3x: $line-space * 3; // 72px
$line-space-4x: $line-space * 4; // 96px
$line-space-7x: $line-space * 7; // 184px
$line-space-8x: $line-space * 8; // 184px

$line-space-half: $line-space * 0.5; // 12px;
$line-space-quarter: $line-space * 0.25; // 6px;

$section-space-mobile: $line-space-2x;
$section-space: $line-space-4x;

// Content

$text-max-width: 840px;
$text-max-width-wide: 950px;
$text-max-width-small: 620px;

//
// Z-indexes
//


$z-index-header: 30;
$z-index-logo: 31;
$z-index-nav: 20;
$z-index-nav-dropdown: 30;
$z-index-nav-overlay: 30;
$z-index-nav-overlay-bg: 10;
$z-index-dropdown: 9;
