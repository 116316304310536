//
// Base styles
//

.hero {
  position: relative;

  .hero__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    overflow: hidden;

    img {
      object-fit: cover;
      font-family: 'object-fit: cover; object-position: center';
      object-position: 50% 50%;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 1;
    }

    video {
      object-fit: cover;
      border: 0;
      min-width: 100%;
      min-height: 100%;
      height: auto;
      width: auto;
      max-width: 100%;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      z-index: 2;
    }
    &.is-darkened--light,
    &.is-darkened--dark {
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
      }
    }
    &.is-darkened--light {
      &::before {
        background: linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0.1));
      }
      @include breakpoint(md) {
        &::before {
          background: linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0.1));
        }
      }
    }
    &.is-darkened--dark {
      &::before {
        background: linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(0,0,0,0.2));
      }
      @include breakpoint(md) {
        &::before {
          background: linear-gradient(to top, rgba(0,0,0,0.6), rgba(0,0,0,0.2));
        }
      }
    }
  }

  .hero__inner {
    @include make-container();
    position: relative;
    z-index: 4;
  }
}


//
// Plain text hero
//

.hero--plain {
  padding-top: rem(48);

  @include breakpoint(lg) {
    padding-top: rem(192); // @todo: this is not right
  }
}


//
// Spotlight hero with red background
//

.hero--spotlight {
  max-width: none;
  margin-left: -$container-padding-xs;
  margin-right: -$container-padding-xs;
  background: $red;
  @include container-padding();
  @include container-outdent();
  padding-top: $line-space-2x;
  padding-bottom: $line-space-1-5x;

  @include breakpoint(lg) {
    padding-top: $line-space-8x;
    margin-bottom: rem(96);
  }

  .hero__page-header {
    max-width: $text-max-width;

    .is-eyebrow, .is-byline, .h1 {
      color: $white;
    }

    .is-eyebrow {
      display: block;
      margin: -26px 0 rem(8);
    }

    .is-author-image {
      display: block;
      float: left;
      margin-right: 12px;
      position: relative;
      top: 2px;
    }

    h1 {
      margin: 0;
    }

    h1 + .is-byline,
    h1 + .is-author-image,
    .is-author-image + .is-byline {
      margin-top: rem(16);
    }

    h1 + .is-lede {
      margin-top: rem(23);
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
}


//
// Hero with image
//

.hero--page {
  max-width: none;
  margin-left: -$container-padding-xs;
  margin-right: -$container-padding-xs;
  height: 216px;
  display: flex;
  background: $black;
  @include container-padding();
  @include container-outdent();

  .hero__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: rem(24);
  }

  .is-eyebrow {
    color: $white;
  }

  h1 {
    color: $white;
    margin: 0;
  }

  &.hero--light {
    .hero__img {
      background-color: $gray-light;
    }

    .is-eyebrow, h1 {
      color: $black;
    }
  }

  @include breakpoint(lg) {
    height: 480px;
    margin-bottom: rem(96);

    .hero__inner {
      padding-bottom: rem(40);
    }
  }

  .hero__split {
    @include make-row();
    @include row-margins();
    align-items: flex-end;

    .hero__header,
    .hero__content {
      @include make-column();
      @include column-padding();
    }

    @include breakpoint(lg) {
      .hero__header {
        @include make-column(percentage(4/12));
      }

      .hero__content {
        @include make-column(percentage(8/12));
      }
    }
  }

  .hero__img.is-screen-left::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-image: url(../../img/hero-screen.svg);
    background-position: -300px 0;

    @media (min-width: 1620px) {
      background-position: -160px 60%;
    }

    @media (min-width: 1850px) {
      background-position: -80px 60%;
    }

    @media (min-width: 2000px) {
      background-position: 0 60%;
    }

    @include breakpoint-down(lg) {
      background-position: -550px 60%;
    }

    @include breakpoint-down(sm) {
      display: none;
    }
  }

  .hero__img.is-screen-left--dark::before {
    background-image: url(../../img/hero-screen-dark.svg);
  }

  .hero__img.is-screen-left--small::before {
    background-position: -660px -50px;

    @include breakpoint-down(lg) {
      background-position: -660px 60%;
    }
  }

  .hero__img.is-screen-right::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-image: url(../../img/hero-screen-right.svg);
    background-position: calc(100% + 490px) 0;

    @include breakpoint-down(lg) {
      background-position: calc(100% + 690px) 60%;
    }

    @include breakpoint-down(sm) {
      display: none;
    }
  }
}

//
// Hero with next/prev images and links
//

.hero--pager {
  max-width: none;
  padding: 0 !important;
  margin-top: 4px;
  height: 216px;
  display: flex;
  flex-direction: row;
  background: $black;
  @include container-outdent();

  @include breakpoint(lg) {
    height: 520px;
    margin-bottom: rem(96);
  }

  .hero__track {
    height: 216px;
    width: 100%;
    position: relative;
    overflow: hidden;

    img {
      height: 216px;
      width: auto;
      max-width: calc(100% - 40px);

      &.is-active-slide {
        background: $white;
        padding: 0 4px;
      }

      &:not(.is-active-slide) {
        filter: grayscale(100%) brightness(0.3);
      }

      @media (min-width: 880px) {
        max-width: 840px;
      };
    }

    @include breakpoint(lg) {
      height: 520px;

      img {
        height: 520px;
      }
    }
  }



  .hero__prev,
  .hero__next {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 20px;
    height: 100%;
    z-index: 4;
    border: 0;
    text-decoration: none;

    @include breakpoint(md) {
      width: 38px;
    }

    @include breakpoint(lg) {
      width: 48px;
    }

    svg {
      position: absolute;
      background: none;
      stroke: $white;
      stroke-width: 6;
      fill: none;
      left: 50%;
      top: 50%;
      width: 20px;
      height: 20px;
      transform: translate3d(-50%,-50%,0);
      transition: $transition-link;

      @include breakpoint(md) {
        stroke-width: 3;
        width: 38px;
        height: 38px;
      }
    }

    &:hover, &:focus {
      svg {
        stroke: $red;
      }
    }
  }

  .hero__next {
    left: auto;
    right: 0;
    transform: rotate(180deg);
  }
}


//
// Home hero with slider
//

/*
Markup:
.hero.hero--home
  .slider
    .slider__slide
      .hero__inner
        .hero__content
          .eyebrow
          h1
      .hero__news[.hero__news--top, .hero__news--bottom]
        .hero__news-inner
          .is-eyebrow.is-small
          p.h3
            a
          .card__footer
      .hero__img[.is-darkened--light, .is-darkened--dark, .is-darkened--none]
        picture
          source
          img
*/

.hero--home {
  max-width: none;
  position: relative;
  background: transparent;
  margin-bottom: rem(70);

  @include container-outdent();

  &,
  .slider__slide,
  .hero__inner {
    height: calc(100vh - 112px);
    min-height: 450px;
  }

  .slider__slide {
    position: relative;
    width: 100%;
    @include container-padding();
    background: $black;
    overflow: hidden;
  }

  .hero__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding-top: rem(49);
    padding-bottom: rem(49);
    position: relative;
    @include make-container();
  }

  .hero__content {
    max-width: 510px;

    @include breakpoint-only(md, lg) {
      max-width: 50%;
    }

    @include breakpoint(xl) {
      max-width: 660px;
    }
  }

  .is-eyebrow {
    color: $white;
    font-size: rem(18);
    line-height: (24/18);

    @include breakpoint(lg) {
      font-size: rem(27);
      line-height: (36/27);
      letter-spacing: -0.4px;
    }
  }

  h1 {
    color: $white;
    margin: 0.215em 0 0;

    @include breakpoint-down(lg) {
      font-size: rem(22);
      line-height: (28/22);
      font-weight: $font-weight-semibold;
    }

    @include breakpoint(lg) {
      margin: 0.65em 0 0;
    }
  }

  .hero__news {
    position: absolute;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    background-image: url(../../img/hero-screen-news-mobile.svg);
    background-position: left top;
    padding: 18px 15px 15px 24px;
    width: 355px;
    max-width: 100%;
    min-height: 202px;
    z-index: 4;
    display: flex;
    align-items: center;

    @include breakpoint(lg) {
      background-image: url(../../img/hero-screen-news-bottom.svg);
      width: 440px;
      min-height: 240px;
      padding: 40px 40px 55px 80px;
    }

    @include breakpoint(xl) {
       width: 506px;
       padding: 40px 80px 55px 80px;
    }

    a {
      border: 0;
      text-decoration: none;
      color: $black;
      transition: $transition-link;

      &:hover, &:focus {
        color: $red;
      }
    }

    .h3 {
      margin-bottom: rem(6);
    }

    .is-eyebrow {
      color: $text-muted;
      font-size: rem(13);
      line-height: rem(18);
    }

    .is-eyebrow + .h3 {
      margin-top: rem(12);
    }

    &:hover, &:active {
      .h3 {
        color: $red;

      }
    }

    .news__footer {
      color: $text-muted;
    }
  }

  @include breakpoint(md) {
    .hero__inner {
      flex-direction: row;
      align-items: flex-end;
      padding-bottom: rem(86);
    }
  }

  @include breakpoint(lg) {
    margin-bottom: rem(96);

    &,
    .slider__slide,
    .hero__inner {
      height: calc(100vh - 132px);
      min-height: 230px;
    }
  }
}

//
// Hero news at the top

.hero--home {
  .hero__news--top {

    @include breakpoint(md) {
      bottom: auto;
      top: 0;
      padding: 15px 15px 18px 24px;
      background-image: url(../../img/hero-screen-news-top-mobile.svg);
      background-position: left bottom;
    }

    @include breakpoint(lg) {
      padding: 55px 40px 50px 80px;
      background-image: url(../../img/hero-screen-news-top.svg);
      background-position: left bottom;
    }

    @include breakpoint(xl) {
       padding: 55px 80px 50px 80px;
    }

  }
}

//
// Transitions and states
// (limited to desktop)

.hero--home {

  @include breakpoint(lg) {

    // When unselected

    .slider__slide {

      // Hero text

      .hero__content {
        opacity: 0.0;
        transform: translate(25%, 0);
        transition: opacity 0.3s linear,
          transform 0.1s linear 0.4s; /* delay transform transition so it happens after opacity is finished */
      }

      // News item

      .hero__news {
        opacity: 0.0;
        transform: translate(50%, 50%);
        transition: opacity 0.3s linear,
          transform 0.1s linear 0.4s; /* delay transform transition so it happens after opacity is finished */
      }

      .hero__news--top {
        transform: translate(50%, -50%);
      }

    }

    // When selected

    .slider__slide.is-selected {

      // Hero text

      .hero__content {
        opacity: 1;
        transform: translate(0,0);
        transition: opacity 0.7s ease 0.2s, /* slightly delay opacity so it's already sliding before fade in starts */
          transform 0.7s ease 0.1s;
      }

      // News item

      .hero__news {
        opacity: 1;
        transform: translate(0,0);
        transition: opacity 0.6s linear 0.3s, /* slightly delay opacity so it's already sliding before fade in starts */
          transform 0.9s ease 0.2s;
      }
    }

  }

}

//
// Reduced motion styles

@media screen and (prefers-reduced-motion: reduce) {

  .hero--home .hero__img video {
    display: none;
  }

  .hero--home .slider__slide .hero__content,
  .hero--home .slider__slide .hero__news,
  .hero--home .slider__slide.is-selected .hero__content,
  .hero--home .slider__slide.is-selected .hero__news {
    transition: none;
  }

}



