.block--divider {
  margin-top: rem(24);
  margin-bottom: rem(24);

  @include breakpoint(lg) {
    margin-top: rem(48);
    margin-bottom: rem(48);
  }

  hr {
    margin: 0;
  }

  &.is-divider-stealth {
    hr {
      visibility: hidden;
    }
  }
}
