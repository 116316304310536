// List Reset
//
// Usage:
// .element { @include list-reset(); }
//
@mixin list-reset {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
    padding: 0;
    text-indent: 0;
  }

  li::before {
    display: none;
  }
}

// Button Reset
//
// Usage:
// .element { @include button-reset(); }
//
@mixin button-reset {
  appearance: none;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
}

// Rem Sizing
//
// Usage:
// font-size: rem(24); // outputs 1.5rem
//
@function rem($size, $baseSize: 16) {
  $rem: $size / $baseSize;
  @return #{$rem}rem;
}

// Turn font smoothing off or on
// http://maximilianhoffmann.com/posts/better-font-rendering-on-osx

// Usage:

//   .dark-on-light { @include font-smoothing(off); }
//   .light-on-dark { @include font-smoothing(on); }
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// Encode a color variable for svg
@function hex-encode($color) {
  @return '%23' + str-slice(#{$color}, 2, -1);
}
