//
// Tabs
//

// @todo: overflow-x: scroll for mobile and fade out right side

.tabs {
  .tabs__nav {
    overflow-x: auto;

    // @hack hidden scrollbars
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
      width: 0px;
      background: transparent;
    }
    // END @hack
  }

  .tabs__tablist {
    @include list-reset();

    display: flex;
    border-bottom: 1px solid $gray-rule;

    li {
      margin-right: rem(16);
      margin-bottom: -1px;

      @include breakpoint(lg) {
        margin-right: rem(24);
      }
    }

    a {
      color: $text-color;
      border-bottom: 1px solid transparent;
      display: inline-block;
      padding: rem(5)  0 rem(17);
      font-size: $font-size-base;
      line-height: $line-height-base;
      font-weight: $font-weight-semibold;
      white-space: nowrap;
    }

    a:hover,
    a:active {
      color: $red;
    }

    a[aria-selected="true"] {
      color: $red;
      border-color: $red;
    }
  }

  .tabs__content {
    padding-top: $line-space;

    @include breakpoint(lg) {
      padding-top: $line-space-2-5x;
    }
  }

  .tabs__pane {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;

    &.show {
      height: auto;
      opacity: 1;

    }
  }
}
