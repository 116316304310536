//
// Fast fact card
//

/*
Markup:
.card.card--fast-fact.text-center.bg-teal
  .card__header
    .h1-jumbo
  .card__footer
    .h4
*/

.card--fast-fact {
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $line-space-2x;
  aspect-ratio: 1/1;
  background-color: $teal-light;


  .card__header,
  .card__footer {
    transform: translateY(#{-$baseline-grid-unit});
  }

  .card__header {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-bottom: $line-space-half;
    padding-bottom: $line-space-half;

    > * {
      margin: 0;
      padding: 0;
    }
  }

  .card__footer p {
    margin-bottom: 0;
    line-height: $line-height-tight;
    transform: translateY(2px); // optically adjust downward
  }
}

//
// States for cards that are linked

a.card--fast-fact {

    transition: background-color .3s;

  .card__header,
  .card__header p,
  .card__footer p {
    transition: color .3s, border-color .3s;
  }

  &:hover {
    background-color: $black;
    .card__header,
    .card__header p {
      color: $white;
      border-color: $gray-medium;
    }
    .card__footer p {
      color: $gray-rule;
    }

  }
}
