.action {
  font-weight: $font-weight-semibold;
  font-size: rem(16);
  line-height: (24/16);
  border: 0;
  color: $red;
  display: inline-block;
  padding-right: 18px;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  background-position: 100% calc(50% + 1px);
  background-image: url("data:image/svg+xml,%3Csvg height='13' viewBox='0 0 13 13' width='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.26666667 7.93333333h10.53191553l-4.86524887-4.86527898v-.80138768h.80138769l6.23333338 6.23333333-6.23333338 6.2333333h-.80138769v-.8013877l4.86524887-4.86527893h-10.53191553z' fill='#{hex_encode($red)}' transform='translate(-2 -2)'/%3E%3C/svg%3E");
  transition: $transition-link;

  &:hover,
  &:active,
  a:hover &,
  a:active & {
    color: $black;
    padding-right: 22px;
    background-image: url("data:image/svg+xml,%3Csvg height='13' viewBox='0 0 13 13' width='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.26666667 7.93333333h10.53191553l-4.86524887-4.86527898v-.80138768h.80138769l6.23333338 6.23333333-6.23333338 6.2333333h-.80138769v-.8013877l4.86524887-4.86527893h-10.53191553z' fill='#{hex_encode($black)}' transform='translate(-2 -2)'/%3E%3C/svg%3E");
  }
}

.action.action-reverse,
.bg-dark .action {
  color: $white;
  background-image: url("data:image/svg+xml,%3Csvg height='13' viewBox='0 0 13 13' width='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.26666667 7.93333333h10.53191553l-4.86524887-4.86527898v-.80138768h.80138769l6.23333338 6.23333333-6.23333338 6.2333333h-.80138769v-.8013877l4.86524887-4.86527893h-10.53191553z' fill='#{hex_encode($white)}' transform='translate(-2 -2)'/%3E%3C/svg%3E");

  &:hover,
  &:active,
  a:hover &,
  a:active & {
    color: $white;
    background-image: url("data:image/svg+xml,%3Csvg height='13' viewBox='0 0 13 13' width='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.26666667 7.93333333h10.53191553l-4.86524887-4.86527898v-.80138768h.80138769l6.23333338 6.23333333-6.23333338 6.2333333h-.80138769v-.8013877l4.86524887-4.86527893h-10.53191553z' fill='#{hex_encode($white)}' transform='translate(-2 -2)'/%3E%3C/svg%3E");
  }
}

.bg-light .action {
  color: $black;
  background-image: url("data:image/svg+xml,%3Csvg height='13' viewBox='0 0 13 13' width='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.26666667 7.93333333h10.53191553l-4.86524887-4.86527898v-.80138768h.80138769l6.23333338 6.23333333-6.23333338 6.2333333h-.80138769v-.8013877l4.86524887-4.86527893h-10.53191553z' fill='#{hex_encode($black)}' transform='translate(-2 -2)'/%3E%3C/svg%3E");

  &:hover,
  &:active,
  a:hover &,
  a:active & {
    color: $black;
    background-image: url("data:image/svg+xml,%3Csvg height='13' viewBox='0 0 13 13' width='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.26666667 7.93333333h10.53191553l-4.86524887-4.86527898v-.80138768h.80138769l6.23333338 6.23333333-6.23333338 6.2333333h-.80138769v-.8013877l4.86524887-4.86527893h-10.53191553z' fill='#{hex_encode($black)}' transform='translate(-2 -2)'/%3E%3C/svg%3E");
  }
}

.action-plus {
  background-position: 100% 50%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cpath d='M9.602 2.398v6h6v1.204h-6v6H8.398v-6h-6V8.398h6v-6Zm0 0' style='stroke:none;fill-rule:evenodd;fill:#{hex_encode($red)};fill-opacity:1'/%3E%3C/svg%3E");
  background-size: 18px 18px;
  padding-right: 24px;

  &:hover,
  &:active,
  a:hover &,
  a:active & {
    padding-right: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cpath d='M9.602 2.398v6h6v1.204h-6v6H8.398v-6h-6V8.398h6v-6Zm0 0' style='stroke:none;fill-rule:evenodd;fill:#{hex_encode($black)};fill-opacity:1'/%3E%3C/svg%3E");
    background-size: 18px 18px;
  }
}

.action-close {
  background-position: 100% 50%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cpath d='M3.25 2.398 9 8.152l5.75-5.754h.852v.852L9.848 9l5.754 5.75v.852h-.852L9 9.848l-5.75 5.754h-.852v-.852L8.152 9 2.398 3.25v-.852Zm0 0' style='stroke:none;fill-rule:nonzero;fill:#{hex_encode($red)};fill-opacity:1'/%3E%3C/svg%3E");
  background-size: 18px 18px;
  padding-right: 22px;

  &:hover,
  &:active,
  a:hover &,
  a:active & {
    padding-right: 22px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cpath d='M3.25 2.398 9 8.152l5.75-5.754h.852v.852L9.848 9l5.754 5.75v.852h-.852L9 9.848l-5.75 5.754h-.852v-.852L8.152 9 2.398 3.25v-.852Zm0 0' style='stroke:none;fill-rule:nonzero;fill:#{hex_encode($black)};fill-opacity:1'/%3E%3C/svg%3E");
    background-size: 18px 18px;
  }
}

.action-download {
  background-position: 100% 50%;
  background-image: url("data:image/svg+xml,%3Csvg height='13' viewBox='0 0 13 13' width='13' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3CclipPath id='a'%3E%3Cpath d='m7.07.27v8.26l3.73-3.73h.8v.8l-5.1 5.1-5.1-5.1v-.8h.8l3.73 3.73v-8.26z'/%3E%3C/clipPath%3E%3CclipPath id='b'%3E%3Cpath d='m-299-622h1440v1713h-1440z'/%3E%3C/clipPath%3E%3CclipPath id='c'%3E%3Cpath d='m12.73 12.73h-12.46v-3.4h1.13v2.27h10.2v-2.27h1.13z'/%3E%3C/clipPath%3E%3Cg clip-path='url(%23a)'%3E%3Cg clip-path='url(%23b)'%3E%3Cpath d='m.4-.73h12.2v12.43h-12.2z' fill='#{hex_encode($red)}'/%3E%3C/g%3E%3C/g%3E%3Cg clip-path='url(%23c)'%3E%3Cg clip-path='url(%23b)'%3E%3Cpath d='m-.73 8.33h14.47v5.4h-14.47z' fill='#{hex_encode($red)}'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

  &:hover,
  &:active,
  a:hover &,
  a:active & {
    background-image: url("data:image/svg+xml,%3Csvg height='13' viewBox='0 0 13 13' width='13' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3CclipPath id='a'%3E%3Cpath d='m7.07.27v8.26l3.73-3.73h.8v.8l-5.1 5.1-5.1-5.1v-.8h.8l3.73 3.73v-8.26z'/%3E%3C/clipPath%3E%3CclipPath id='b'%3E%3Cpath d='m-299-622h1440v1713h-1440z'/%3E%3C/clipPath%3E%3CclipPath id='c'%3E%3Cpath d='m12.73 12.73h-12.46v-3.4h1.13v2.27h10.2v-2.27h1.13z'/%3E%3C/clipPath%3E%3Cg clip-path='url(%23a)'%3E%3Cg clip-path='url(%23b)'%3E%3Cpath d='m.4-.73h12.2v12.43h-12.2z' fill='#{hex_encode($black)}'/%3E%3C/g%3E%3C/g%3E%3Cg clip-path='url(%23c)'%3E%3Cg clip-path='url(%23b)'%3E%3Cpath d='m-.73 8.33h14.47v5.4h-14.47z' fill='#{hex_encode($black)}'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
}

.action-list {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0 0 $line-space-half;
    padding: 0;
    text-indent: 0;
  }
}

.action-list--hor {
  display: flex;
  flex-direction: row;
  gap: $line-space-half;

  li:not(:first-child) {
    padding-left: $line-space-half;
    border-left: 1px solid $gray-rule;
  }

  /* Don't allow the icon to move when these are in a horizontal list */
  .action:hover {
    padding-right: 18px;
  }
  .action-close:hover {
    padding-right: 22px;
  }
  .action-plus:hover {
    padding-right: 24px;
  }
}
