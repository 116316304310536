.block--text {
  *:last-child {
    margin-bottom: 0;
  }

  .block__inner {
    max-width: $text-max-width;
  }

  /* Table on the privacy policy */
  table {
    border-collapse: collapse;
    border: 1px solid $gray-rule;
    border-bottom: 0;
    margin: 0 0 rem(24) 0;

    th, td {
      vertical-align: top;
      border-bottom: 1px solid $gray-rule;
      padding: $line-space-half;
    }
  }
}

.block--text-wide {
  *:last-child {
    margin-bottom: 0;
  }

  .block__inner {
    max-width: $text-max-width-wide;
  }
}

.block--text + .block--text {
  margin-top: $line-space-2x;
}

.hero--page + .block--text {
  margin-top: $line-space-2x;

  @include breakpoint(lg) {
    margin-top: $line-space-4x;
  }
}
