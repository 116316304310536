
.block.block--tabs {
  .page-header + & {
    margin-top: 0;
  }

  .block--filters,
  .block--perspectives {
    margin-top: $line-space;

    @include breakpoint(lg) {
      margin-top: $line-space-2x;
    }
  }
}
