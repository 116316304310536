//
// Perspective card with or without images
//

.card.card--perspective {
  background: $gray-light;
  display: block;
  border: 0;
  color: inherit;

  .card__body {
    padding: rem(20);
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      min-height: rem(190);
    }

    @include breakpoint(lg) {
      padding: rem(24);
    }

    .h3 {
      transition: $transition-link;
      margin-bottom: rem(6);
    }

    .is-eyebrow + .h3 {
      margin-top: rem(12);
    }

  }

  .card__footer {
    margin-top: auto;
  }

  &:hover, &:active {
    .card__body {
      .h3 {
        color: $red;
      }
    }

    .card__img img {
      filter: grayscale(100%) brightness(0.3);
    }
  }

  &.is-featured {
    flex: 1;
    display: flex;
    flex-direction: column;

    .card__body {
      flex: 1;
    }

    .card__footer {
      margin-top: auto;
      padding-top: $line-space;
    }

    .card__body,
    .card__footer {
      > * {
        max-width: 485px;
      }
    }

    @include breakpoint(lg) {
      min-height: 480px;
    }
  }

  &.is-spotlight {
    background: $red;
    color: $white;

    .is-eyebrow, .h2, .h3 {
      color: $white;
      // prevents flicker on hover
      transform:translate3d(0,0,0);
    }

    .is-author-image {
      display: block;
      float: left;
      margin-right: 12px;
      position: relative;
      top: 1px;
    }

    &:hover, &:active {
      .card__body {
        .h2, .h3 {
          color: $white;
          opacity: 0.6;
        }
      }
    }
  }

  &.is-side-by-side {
    @include breakpoint(lg) {
      flex-direction: row;

      .card__body,
      .card__img {
        $width: percentage(1/2);
        flex-basis: $width;
        max-width: $width;
      }

      .card__body {
        padding-right: $grid-gutter-width-lg / 2;
      }

      .card__img {
        $width: percentage(1/2);
        flex-basis: $width;
        max-width: $width;
        padding-left: $grid-gutter-width-lg / 2;

        img {
          object-fit: cover;
          font-family: 'object-fit: cover; object-position: center';
          object-position: 50% 50%;
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
}

.block--perspectives .grid__col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
