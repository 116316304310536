//
// Infographics
//

.block--infographic {
  header {
    margin-bottom: $line-space;
    * {
      margin: 0;
    }
  }

  figure img {
  }

  footer {
    margin-top: $line-space;
    p:last-child {
      margin-bottom: 0;
    }
  }
}
