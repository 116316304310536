.icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  fill: currentColor;
}

.icon-search {}
.icon-close {}
.icon-menu {}

.icon-facebook,
.icon-linkedin,
.icon-twitter,
.icon-vimeo,
.icon-youtube {
  width: 30px;
  height: 30px;
}
