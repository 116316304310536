
.block--investments-legend {
  margin-bottom: $line-space;

  > div {
    display: inline-block;
    margin-right: rem(20);
  }

  .marker {
    display: inline-block;
    vertical-align: middle;
    bottom: 1px;
    position: relative;
    margin-right: 2px;
  }
}

.block--investments-map {
  background-color: #77AAC1;

  .map-tooltip {
    padding:  20px;
    max-width: 360px;

    .h4, p {
      color:  $white;
      margin:  0;
    }

    p + p {
      margin-top:  0.25em;
    }

    a {
      color: $white;
      border-color:  rgba($white, 0.7);
    }
  }

  @media(min-width: 1440px) {
    .embed-responsive {
      height: 900px;
      padding: 0;
    }
  }
}

// Work-arounds for full width tab content

.investments-map-wrapper {
  margin-top: 0;

  &.show + .block.block--cta:last-child {
    margin-top: 0;
  }

  &.tabs__pane:not(.show) {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  &.tabs__pane.show {
    height: auto;
    opacity: 1;
    transition: opacity 0.5s ease;
  }
}

.block.block--tabs + .investments-map-wrapper {
  margin: 0;
}

// Modal map

.investments-map-modal {
  background-color: #77AAC1;
}
