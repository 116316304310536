// styleguide.scss
//
// Style Guide styles


$sg-pink: #e30087;


.swatch {
  font-size: 14px;
  font-family: $font-family-base;
  line-height: 1.2;
  padding: 10px;
  min-height: 90px;

  &:not([class*="text-"]) {
    color: $white;
  }

  .grid--no-gutters & {
    padding: 10px;
  }

  strong {
    display: block;
  }
}

.sg-icons {
  svg {
    display: inline-block;
    margin: 15px 5px;
  }
}

.debug {
  border: 1px solid $sg-pink;
}

.is-note {
  color: $sg-pink;
}

.label.is-note {
  color: $white;
  background: $sg-pink;
}

.sg-jumpnav {
  li {
    display: inline-block;
    margin: 0.5em;
    font-size: rem(16);
  }

  a {
    text-decoration: none;
  }
}

.sg-stickynav {
  position: sticky;
  top: 0;
  margin: 0;
  padding: rem(20) 0;
  border-bottom: 1px solid $gray-rule;
  z-index: 1080;
}

// .sg-block {
//   margin-top: 0 !important;
//   padding-top: rem(80);
// }

.sg-block + .hero {
  margin-top: $line-space;

  @include breakpoint(lg) {
    margin-top: $line-space-2x;
  }
}

.debug-grid {
  background: lighten($sg-pink, 30%);
  text-align: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

body.styleguide code {
  font-size: rem(12);
  background: lighten($sg-pink, 50%);
  padding: 3px;
}

body.debug-breakpoints {
    &::after {
        content: "mobile";
        background: $sg-pink;
        color: $white;
        display: block;
        padding: 5px 10px;
        position: fixed;
        bottom: 0;
        right: 0;
        font-family: $font-family-base;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 1px;
        z-index: 999999;

        @include breakpoint(sm) {
            content: "small";
        }

        @include breakpoint(md) {
            content: "medium";
        }

        @include breakpoint(lg) {
            content: "large";
        }

        @include breakpoint(xl) {
            content: "x-large";
        }
    }
}
