.block--cta {
  position: relative;
  @include container-padding();
  padding-top: rem(48);
  padding-bottom: rem(72);
  background: $black;

  &:last-child {
    margin-bottom: 0;
  }

  .cta__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;

    img {
      object-fit: cover;
      font-family: 'object-fit: cover; object-position: center';
      object-position: 50% 50%;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .cta__inner {
    @include make-container();
    position: relative;
    z-index: 3;
    color: $white;

    h2, p {
      color: inherit;
    }

    p {
      font-size: rem(16);
      line-height: (24/16);
      font-weight: $font-weight-semibold;
    }

    .grid__col > *:last-child {
      margin-bottom: 0;
    }

    .cta__action {
      margin-top: rem(24);
    }
  }

  @include breakpoint(lg) {
    min-height: 288px;
    padding-top: rem(48);
    padding-bottom: rem(96);

    .cta__header {
      $width: percentage(4/12);
      flex-basis: $width;
      max-width: $width;

      h2 {
        margin: 0;
      }
    }

    .cta__content {
      $width: percentage(6/12);
      flex-basis: $width;
      max-width: $width;
    }
  }
}

.block--cta.bg-gray {
  .cta__inner {
    color: $black;

    h2, p {
      color: inherit;
    }

    p {
      font-size: rem(16);
      line-height: (24/16);
      font-weight: $font-weight-normal;
    }
  }

  @include breakpoint(lg) {
    min-height: 288px;
    padding-top: rem(48);
    padding-bottom: rem(96);

    .cta__content {
      flex: 0 0 percentage(8/12);
      max-width: percentage(8/12);
      padding-top: rem(10); // line up subheadings
    }

    .grid__col.w-3of8-lg {
      $width: percentage(3/8);
      flex-basis: $width;
      max-width: $width;
    }

    .grid__col.w-5of8-lg {
      $width: percentage(5/8);
      flex-basis: $width;
      max-width: $width;
    }
  }
}
