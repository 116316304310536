
// logo
.snapshot-branding {
  height: 24px;

  @include breakpoint(lg) {
    height: 48px;
  }

  img {
    display: block;

    &.is-png {
      height: 30px;
      width: auto;
    }
  }

  & + .snapshot {
    margin-top: rem(24);

    @include breakpoint(lg) {
      margin-top: rem(40);
    }
  }
}

// info box
.snapshot {
  background: $gray-light;
  width: 100%;

  @include breakpoint(xl) {
    max-width: 290px;
  }

  .snapshot__group {
    padding: rem(20);

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .snapshot__group + .snapshot__group {
    border-top: 2px solid $white;
  }

  .snapshot__heading {
    display: block;
    margin-bottom: rem(12);
  }



  // Lists - Industries, Locations
  .snapshot__list {
    @include list-reset();

    li + li {
      margin-top: rem(12);
    }

    .is-small {
      display: block;
      width: 100%;
      margin-top: rem(4);
    }
  }

  .snapshot__action {
    margin-top: rem(24);
  }

}

// Alignment in sidebar
.snapshot, .snapshot-branding {
  margin-right: auto;

  .section--sidebar-right & {
    margin-right: 0;
    margin-left: auto;
  }
}
