@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

%animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fade-in {
  @extend %animated;
  animation-name: fadeIn;
}

.fade-in-down {
  @extend %animated;
  animation-name: fadeInDown;
}
