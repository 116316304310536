//
// Slider block
//
// Just offsets the spacing for the dots
//

.block--slider {
  margin-bottom: calc(#{$line-space-2x} + 20px);

  @include breakpoint(lg) {
    margin-bottom: calc(#{$line-space-2x} + 32px);
  }

  .section__content & {
    margin-bottom: 20px;

    @include breakpoint(lg) {
      margin-bottom: 32px;
    }
  }
}
