.btn {
  display: inline-block;
  font-family: $font-family-base;
  font-weight: $font-weight-semibold;
  font-size: rem(15);
  color: $red;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid $red;
  border-radius: 0;
  padding: 7px 10px;
  line-height: (24/15);
  transition: $transition-btn;
  text-decoration: none;
  max-width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover,
  &:focus {
    color: $black;
    border-color: $black;
    text-decoration: none;
    cursor: pointer;
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  @include breakpoint(lg) {
    padding: 11px 18px;
  }

  &.btn-reverse {
    color: $white;
    border-color: $white;

    &:hover,
    &:focus {
      color: $white;
      border-color: $white;
      opacity: 0.65;
    }

    &.btn-red {
      color: $red;
      border-color: $red;

      &:hover,
      &:focus {
        color: $red;
        border-color: $red;
        //opacity: 0.65;
      }
    }
  }

  &.btn-small,
  &.btn-label {
    font-size: rem(13);
    line-height: (18/13);
    letter-spacing: 0.5px;
    padding: 4px 8px 5px;
    color: $red-text-sm;

    .icon {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin: -6px -3px -5px 2px;
    }

    &:hover,
    &:focus {
      color: $black;
    }
  }

  &.btn-label {
    text-transform: none;
    letter-spacing: 0;
  }

  &[disabled],
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
    background-color: $text-muted;
    border-color: $text-muted;
    color: $white;
    transition: all 0s;
  }
}

