//
// By the numbers cards and collections
//

/*
Markup:
a.card.card--by-the-numbers
  .card__body
    .card__icon
      img.icon--static
      [img.icon--animated]
    .card__content
      .card__category [optional]
        .is-eyebrow.is-small
      .card__title.h1-jumbo-stepped.text-black
      .card__description
*/

.card--by-the-numbers {
  * {
    margin: 0; // reset margins
  }

  display: flex;
  align-items: stretch;
  > * {
    flex: 1;
  }

  .card__body {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: $line-space;
    padding: $line-space;
    background: $gray-light;

    .card__icon {
      position: relative;
      flex: 0 0 96px;
      img {
        display: block;
        border-radius: 50%;
        aspect-ratio: 1/1;
        overflow: hidden;
      }
      .icon--animated {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }

    .card__content {
      flex: 1;

      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-end;
    }

    .card__category {
      flex: 1;
      margin-bottom: $line-space;
      margin-top: -$line-space-quarter;
      color: $gray-medium;
      > * {
        color: inherit;
      }
    }

    .card__title {
      // nada
    }

    .card__description {
      margin-top: $line-space-half;
    }

  }

  @include breakpoint(xl) {
    .card__body {
      .card__icon {
        flex: 0 0 120px;
      }
    }
  }
}

//
// Reduced motion styles

@media screen and (prefers-reduced-motion: reduce) {

  .card--by-the-numbers .card__body .card__icon .icon--animated {
    display: none;
  }

}

//
// States for cards that are linked

a.card--by-the-numbers {

  .card__body {
    transition: background-color .3s;
  }

  .card__title,
  .card__description,
  .card__category {
    transition: color .3s;
  }

  &:hover {
    .card__body {
      background-color: $black;
    }
    .card__title {
      color: $white;
    }
    .card__description,
    .card__category {
      color: $gray-rule;
    }
  }
}

//
// Collections
//

/*
Markup:
ul.by-the-numbers-collection[.by-the-numbers-collection--2up, .by-the-numbers-collection--3up, .by-the-numbers-collection--4up]
  li
    .card
*/

.by-the-numbers-collection {

  // Reset

  margin: 0;
  padding: 0;
  list-style: none;
  > li {
    margin: 0;
    padding: 0;
  }

  // Base styles

  background: $gray-rule;

  // Layout

  display: grid;
  grid-template-rows: 1fr;
  gap: 1px;

  @include breakpoint(lg) {

    .card--by-the-numbers {
      height: 100%; // Make cards take up full height of grid cells
    }

    // 2-up layout

    &.by-the-numbers-collection--2up {
      grid-template-columns: 1fr 1fr;

      > li {
        min-height: #{$line-space * 16};
      }
    }

    // 3-up layout

    &.by-the-numbers-collection--3up {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      > li {
        min-height: #{$line-space * 8};
      }

      > li:nth-child(2) {
        grid-row: span 2;
      }
    }

    // 4-up layout

    &.by-the-numbers-collection--4up {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      > li {
        min-height: #{$line-space * 8};
      }
    }
  }

  @include breakpoint(xl) {

    // 2-up layout

    &.by-the-numbers-collection--2up {
      > li {
        min-height: #{$line-space * 20};
      }
    }

    // 3-up layout

    &.by-the-numbers-collection--3up {
      > li {
        min-height: #{$line-space * 10};
      }
    }

    // 4-up layout

    &.by-the-numbers-collection--4up {
      > li {
        min-height: #{$line-space * 10};
      }
    }
  }
}

//
// Actions

.by-the-numbers-actions {
  margin-top: $line-space;

  .action-list {
    margin-bottom: 0;
    li {
      margin: 0;
    }
  }
}
