//
// Company card a grid
//

.card.card--company {
  display: block;
  border: 0;
  color: inherit;
  border-top: 1px solid $gray-rule;
  padding-top: rem(12);

  img {
    display: block;
    transition: $transition-link;
    transform-origin: 0 100%;
  }

  .card__body {
    padding: 0;
    display: flex;
    flex-direction: column;


    .is-eyebrow + .card__logo {
      margin-top: rem(12);
    }

    .card__logo {
      img.is-png {
        height: 28px; // 30 looked too big
        width: auto;
      }

      span {
        display: block;
        margin-top: rem(8);
        color: inherit;
      }
    }

  }

  // When the card is a link

  &:hover, &:active {
    .card__logo {
      color: $text-muted;

      img {
        opacity: 0.5;
      }
    }
  }

  // Remove top rule on first row for mobile

  .grid__col:first-child & {
    border-top: 0;
    padding-top: 0;
  }

  // Add border after first row

  @include breakpoint-only(md, lg) {
    .grid--2up-md > .grid__col:nth-child(-n+2) & {
      border-top: 0;
      padding-top: 0;
    }
  }

  @include breakpoint(lg) {
    .grid--3up-lg > .grid__col:nth-child(-n+3) & {
      border-top: 0;
      padding-top: 0;
    }

    .grid--2up-lg > .grid__col:nth-child(-n+2) & {
      border-top: 0;
      padding-top: 0;
    }
  }
}


