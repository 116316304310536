//
// Timeline
//
.block--timeline {
  position: relative;

  background: $red;
  margin-top: 0;
  height: 72px;

  &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        border-top: 1px solid $white;
        position: absolute;
        top: 35px;
        left: 0;
        z-index: 1;

        @include breakpoint(lg) {
          top: 47px;
        }
      }

  @include breakpoint(lg) {
    height: 96px;
  }

  .timeline {
    padding: 0 20px;
    max-width: $container-max-width + 40px;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    color: $white;
  }

  .timeline__slider {
    position: relative;



    .timeline__slide {
      position: relative;
      padding-top: 32px;
      min-width: 16px;

      @include breakpoint(lg) {
        padding-top: 43px;
      }
    }

    .timeline__date-start,
    .timeline__date-end {
      position: absolute;
      top: 10px;
      left: 0;
      font-size: rem(13);
      font-weight: $font-weight-semibold;

      @include breakpoint(lg) {
        top: 20px;
      }
    }

    .timeline__date-end {
      left: auto;
      right: 0;
    }

    .timeline__marker {
      display: block;
      width: 9px;
      height: 9px;
      border: 2px solid $white;
      border-radius: 9px;
      background: $red;

      @include breakpoint(lg) {
        width: 11px;
        height: 11px;
        border-radius: 11px;
      }

      &:hover, &:focus, &.is-active {
        background: $white;
      }
    }

    .timeline__marker + .timeline__marker {
      margin-top: 3px;

      @include breakpoint(lg) {
        margin-top: 2px;
      }
    }
  }


}

//
// Timeline grid
//

.block--timeline-grid {
  margin-top: $line-space-2x;

  @include breakpoint(lg) {
    margin-top: $line-space-4x;
  }

  .block--divider {
    margin: $line-space-2x 0 0;

    @include breakpoint(lg) {
      margin: $line-space-4x 0 0;
    }

    .block__inner {
      @include column-padding();
    }
  }

  .is-col-empty {
    @include breakpoint-down(lg) {
      display: none;
    }
  }

  .h1-jumbo {
    padding-right: $line-space-2x;
  }

}
