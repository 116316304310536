//
// Site footer
//


.site-footer {
  @include container-padding();
  background-color: $gray-light;

  .footer__inner {
    @include make-container();
  }

  .footer__nav {
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }


  }

  .footer__nav-main {
    order: 2;
    display: flex;
    align-items: flex-end;
    padding: rem(4) 0 rem(14);

    li {
      margin-right: rem(16);

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      color: $gray-dark;
      border: 0;

      &:hover, &:active {
        color: $red;
      }
    }

    @include breakpoint(md) {
      order: 1;
      padding: rem(55) 0 rem(18);

      li {
        margin-right: rem(25);

        &:last-child {
          margin-right: 0;
        }
      }
    }

  }

  .footer__nav-social {
    order: 1;
    display: flex;
    align-items: flex-end;
    padding: rem(12) 0;

    li {
      margin-right: rem(16);

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      color: $red;
      border: 0;

      &:hover,
      &:active {
        color: $black;
      }
    }

    @include breakpoint(md) {
      order: 2;
      padding: 0 0 rem(14);

      li {
        margin-right: rem(25);

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

}
