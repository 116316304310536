@keyframes slideModalIn {
  0% {
    opacity: 0;
    transform: translateX(99%);
  }
  1% {
    opacity: 0;
    transform: translateX(20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

// `.modal-panel` is our custom class
.modaal-wrapper.modal-panel {
  @extend %animated;
  animation-delay: 0s;
  animation-duration: 0.5s;
  animation-name: slideModalIn;

  //
  // SK header
  //

  .modal-header {
    height: 72px;
    padding-top: $line-space;
    padding-bottom: $line-space-half;
    display: flex;
    align-items: flex-end;

    @include breakpoint(lg) {
      height: 96px;
      padding-bottom: rem(18);
    }
  }

  //
  // Tighter spacing
  //

  .hero {
    margin-bottom: 0;
  }

  .block--section-head {
    margin-bottom: $line-space;
  }

  .section + .section {
    margin-top: $line-space;
  }

  //
  // Plugin overrides
  //

  .modaal-container {
    width: 90vw;
    max-width: 1000px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .modaal-content-container {
    @include container-padding();
    padding-top: 0;
    padding-bottom: $line-space-2x;

    @include breakpoint(lg) {
      padding-bottom: $line-space-4x;
    }

    &.is_loading {
      height: 100vh;
      position: relative;
    }
  }

  .modaal-close {
    width: 24px;
    height: 24px;
    top: 24px;
    right: 20px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cmask id='a' fill='%23fff'%3E%3Cpath d='m0 0h32v32h-32z' fill='%23fff' fill-rule='evenodd'/%3E%3C/mask%3E%3Cpath d='m-2-2 36 36m0-36-36 36' fill='none' mask='url(%23a)' stroke='#{hex_encode($red)}' stroke-linecap='square'/%3E%3C/svg%3E");
    border-radius: 0;
    z-index: 9999;
    transition: none;

    @include breakpoint(lg) {
      width: 32px;
      height: 32px;
      top: 32px;
      right: 32px;
      background-size: 32px 32px;
    }

    &::before,
    &::after {
      display: none;
    }

    &:hover,
    &:active {
      background-image: url("data:image/svg+xml,%3Csvg height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cmask id='a' fill='%23fff'%3E%3Cpath d='m0 0h32v32h-32z' fill='%23fff' fill-rule='evenodd'/%3E%3C/mask%3E%3Cpath d='m-2-2 36 36m0-36-36 36' fill='none' mask='url(%23a)' stroke='#{hex_encode($black)}' stroke-linecap='square'/%3E%3C/svg%3E");
    }
  }

}

//
// Overlay
//

@keyframes fadeOverlayIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.7;
  }
}

.modaal-overlay {
  backdrop-filter: blur(5px);
  animation-delay: 0.5s;
  animation-duration: 0.3s;

  @extend %animated;
  animation-name: fadeOverlayIn;
}


//
// loading animation
//
// We may customize this, for now...
// https://projects.lukehaas.me/css-loaders/

@keyframes loader {
  0%,
  80%,
  100% {
    box-shadow: 0 1.5em 0 -0.8em;
  }
  40% {
    box-shadow: 0 1.5em 0 0;
  }
}

.sk-loader,
.sk-loader:before,
.sk-loader:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  animation-fill-mode: both;
  animation: loader 1.8s infinite ease-in-out;
}
.sk-loader {
  color: $gray-rule;
  font-size: 10px;
  margin: 80px auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.75em;
  margin-left: -0.75em;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.sk-loader:before,
.sk-loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.sk-loader:before {
  left: -2.5em;
  animation-delay: -0.32s;
}
.sk-loader:after {
  left: 2.5em;
}

