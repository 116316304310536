//
// Layout
//

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.site-header,
.site-footer {
  flex-shrink: 0;
}

.main {
  @include container-padding();
  flex: 1 0 auto;
  // overflow: hidden;

  // content bottom spacing
  > #search-results:last-child,
  > .section:last-child,
  > .block:last-child:not(.block--full) {
    margin-bottom: $line-space-3x;

    @include breakpoint(lg) {
      margin-bottom: $line-space-4x;
    }
  }

  > .block--cta:last-child {
    margin-top: $line-space-3x;

    @include breakpoint(lg) {
      margin-top: $line-space-4x;
    }
  }
}
