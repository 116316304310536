//
// Site header
//

@include breakpoint-down(lg) {
  body {
    padding-top: 72px; // sticky header height
  }
}
.site-header {
  @include container-padding();
  position: relative;
  height: 72px;

  @include breakpoint-down(lg) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: $white;
    z-index: $z-index-header;

    .is-nav-fixed & {
      border-bottom: 1px solid $gray-rule;
    }
  }

  .header__inner {
    @include make-container();
    padding-top: rem(12);


    @include breakpoint(lg) {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

    }
  }

  @include breakpoint(lg) {
    height: 96px;
  }
}

//
// Logo
//

.header__branding {
  width: 51px;
  padding-bottom: rem(12);
  position: relative;
  z-index: $z-index-logo;

  svg {
    // IE seems to need the size set
    width: 49px;
    height: 38.31px;
  }

  svg path {
    transition: all .3s ease;
  }

  .is-nav-open & {
    svg path {
      fill: $white;
    }
  }

  a {
    border: 0;
  }

  @include breakpoint(lg) {
    width: 96px;
    visibility: visible;

    svg {
      // IE seems to need the size set
      width: 78px;
      height: 61px;
    }
  }

  @include breakpoint-down(lg) {
    // hide when subnav close button is view
    .is-dropdown-open & {
      opacity: 0;
      visibility: hidden;
    }
  }
}


//
// Main nav
//

.header__nav-main,
.header__nav-language {

  // Base styles

  a {
    border: 0;
    font-weight: $font-weight-semibold;
  }


  // Mobile styles

  @include breakpoint-down(lg) {

    li {
      margin-bottom: 1px;
    }

    li.is-last {
      margin-top: $line-space;
    }

    a,
    a:hover,
    a:active,
    li:hover a {
      display: block;
      padding: rem(6) 0;
      color: $white;
      font-size: rem(21);
      line-height: (28/21);
    }
  }

  // Desktop styles

  @include breakpoint(lg) {
    margin-left: rem(5);

    display: flex;
    align-items: flex-end;

    a:hover,
    a:focus,
    a:active,
    > li > a.is-active,
    > li:hover > a {
      color: $red;
    }

    > li {
      position: relative;
    }

    > li > a {
      display: inline-block;
      padding: rem(12);
    }

    .is-mobile-only {
      display: none;
    }
  }
}

.header__nav-utility {
  display: none;

  @include breakpoint(lg) {
    // note: anchor padding is 12px
    display: block;
    margin: 0;
    position: absolute;
    bottom: 5px;
    right: 12px;

    .header__nav-dropdown {
      left: auto;
      right: calc(0 - 12px);

      &::after {
        left: auto;
        right: 26px;
      }
    }
  }
}


//
// Main nav dropdowns / Mobile subnav
//

.header__nav-dropdown {

  // Mobile subnavs

  @include breakpoint-down(lg) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateX(100vw);
  }

  // Desktop dropdowns

  @include breakpoint(lg) {
    position: absolute;
    top: calc(100% - 7px);
    left: 0;
    min-width: 250px;
    transform: translateY(-10px);
    opacity: 0;
    visibility: hidden;
    transition: all 0s;
    border-top: 12px solid transparent;
    z-index: $z-index-nav-dropdown;


    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: -12px;
      left: 24px;
      border-top: 0;
      border-right: 12px solid transparent;
      border-bottom: 12px solid $red;
      border-left: 12px solid transparent;
    }

    ul {
      background: $red;
      padding: rem(24) 0;
    }

    li {
      margin: rem(12) rem(24);
    }

    a {
      display: block;
      color: $white;

      span {
        border-bottom: 1px solid transparent;
        transition: $transition-link;
      }

      &:hover,
      &:active {
        color: $white;

        span {
          border-color: $white;
        }
      }
    }

    .is-dropdown-active & {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      transition: all 0s;
      transition: opacity .3s linear, transform .3s ease;
    }
  }
}


//
// Mobile nav overlay states
//

.header__nav {

  .close,
  .back {
    display: none;
    cursor: pointer;
  }

  @include breakpoint-down(lg) {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 300px;
    z-index: $z-index-nav;
    background: $red;
    padding: rem(120) 0 rem(40);
    transform: translateY(-100%);
    opacity: 0;
    transition: opacity .3s linear, transform .3s ease;
    overflow: hidden;
    @include make-container();

    .header__nav-inner {
      @include container-padding();
    }

    .header__nav-inner > ul {
      position: relative;
    }

    a {
      opacity: 0;
      transition: opacity .3s linear, transform .3s ease;
    }

    ul {
      transition: opacity .3s linear, transform .3s ease;
    }

    .is-nav-open & {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);

      ul > li > a {
        opacity: 1;
      }

      .close {
        display: block;
        cursor: pointer;
      }
    }

    .is-dropdown-open & {

      ul {
        position: relative;
        transform: translateX(-50vw);
      }

      ul > li > a {
        opacity: 0;
      }

      .header__nav-dropdown {
        opacity: 1;
      }

      .header__nav-dropdown > ul > li > a {
        opacity: 1;
      }

      .back {
        display: block;
      }
    }

    li > .header__nav-dropdown {
      visibility: hidden;
    }

    li.is-dropdown-active > .header__nav-dropdown {
      visibility: visible;
    }

    .close {
      @include button-reset();
      color: $white;
      font-weight: $font-weight-semibold;
      position: absolute;
      top: 33px;
      right: 20px;

      .icon {
        margin-left: 5px;
      }

      span {
        position: relative;
        top: -7px;
      }
    }

    .back {
      @include button-reset();
      color: $white;
      font-weight: $font-weight-semibold;
      position: absolute;
      top: 33px;
      left: $container-padding-md;
      transition: opacity .3s linear, transform .3s ease;

      .icon {
        margin-right: 2px;
      }

      span {
        position: relative;
        top: -7px;
      }
    }
  }

  @include breakpoint-down(md) {
    .back {
      left: $container-padding-xs;
    }
  }
}

//
// Search overlay
//

.header__search {
  position: absolute;
  top: 0;
  left: -100vw;
  width: 100%;
  min-height: 300px;
  background: $white;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity .3s linear, transform .3s ease;
  z-index: $z-index-nav-overlay;

  .is-search-open & {
    visibility: visible;
    left: 0;
    opacity: 1;
    transform: translateY(0);
  }


  .header__search-inner {
    @include make-container();
    @include container-padding();
    max-width: $container-max-width + ($container-padding-lg*2);
    padding-top: 125px;
    padding-bottom: 60px;

    @include breakpoint(lg) {
      padding-top: 260px;
    }
  }

  .close {
    @include button-reset();
    color: $black;
    font-weight: $font-weight-semibold;
    position: absolute;
    top: 33px;
    right: 20px;
    cursor: pointer;

    .icon {
      margin-left: 5px;
    }

    span {
      position: relative;
      top: -7px;
    }

    @include breakpoint(lg) {
      top: 56px;
    }
  }
}



//
// Search overlay toggle
//

.header__search-toggle {
  @include button-reset();
  position: absolute;
  top: 34px;
  right: 110px;
  cursor: pointer;
  color: $gray-dark;
  transition: $transition-link;

  &:hover,
  &.is-active {
    color: $red;
  }

  &:active {
    color: $gray-dark;
  }

  @include breakpoint(lg) {
    top: 55px;
    right: 70px;
  }
}


//
// Mobile nav toggle
//

.header__nav-toggle {
  @include button-reset();
  position: absolute;
  top: 34px;
  right: 20px;
  cursor: pointer;
  display: inline-block;
  vertical-align: center;
  font-size: rem(16);
  line-height: (24/16);
  font-weight: $font-weight-semibold;
  display: flex;
  align-items: center;
  color: $gray-dark;
  transition: $transition-link;

  &:hover {
    color: $red;
  }

  &:active {
    color: $gray-dark;
  }

  .icon {
    margin-left: 5px;
  }

  span {
    position: relative;
    top: -1px;
  }

  @include breakpoint(lg) {
    display: none;
  }

}

.nav-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: $z-index-nav-overlay-bg;
  backdrop-filter: blur(5px);

  &.fade-in {
    animation-delay: 0.3s;
    animation-duration: 0.3s;
  }
}
