//
// Cards
//

// Default card style

.card {
  display: block;
  width: 100%;

  > *:last-child {
    margin-bottom: 0;
  }

  .card__img {
    img {
      display: block;
      width: 100%;
      transition: $transition-link;
    }
  }

  .card__body {
    padding-top: $line-space-half;

    .h2, .h3 {
      margin-top: rem(13);

      & + p {
        margin-top: $line-space-half;
      }
    }

    .card__byline {
      display: block;
      font-weight: $font-weight-semibold;
      font-size: rem(15);
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
}

// When the card is a link

a.card {
  border: 0;
  position: relative;

  &:hover,
  &:active {
    h2, .h2, h3, .h3, .h4-h3 {
      transition: $transition-link;
      color: $red;
    }

    .card__img img {
      filter: grayscale(100%) brightness(0.3);
    }
  }
}

// Grayscale modifier

.card__img img.is-grayscale {
  filter: grayscale(100%);
}

// Card types

@import "cards/card-by-the-numbers";
@import "cards/card-company";
@import "cards/card-fast-fact";
@import "cards/card-feature";
@import "cards/card-history";
@import "cards/card-logo";
@import "cards/card-news";
@import "cards/card-perspective";
@import "cards/card-plain";
