//
// Forms
//

$input-border-color: $gray-border;
$input-border-focus-color: $gray-dark;
$input-placeholder-color: $text-muted;
$input-color: $black;
$label-color: $gray-dark;
$error-color: $red;

form, fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

//
// All inputs
//
.form-control {
  font-family: $font-family-base;
  font-size: rem(16);
  font-weight: $font-weight-normal;
  color: $input-color;
  line-height: normal;
  padding: 8px;
  border: 1px solid $input-border-color;
  display: block;
  width: 100%;
  //height: 60px;
  background: $white;
  border-radius: 0;

  @include breakpoint(lg) {
    padding: 12px 10px;
  }

  &::placeholder {
    color: $input-placeholder-color;
    font-weight: $font-weight-normal;
  }

  &:focus,
  &.focus {
    outline: 0;
    border-color: $input-border-focus-color;
  }

  // Hack ugly autocomplete webkit styles
  &:-internal-autofill-selected,
  &:-webkit-autofill {
    -webkit-text-fill-color: $black;
    background-color: $white !important;
    box-shadow: 0 0 0 1000px $white inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.form-control-lg {
  @include breakpoint(lg) {
    font-size: rem(19);
    padding: 16px 17px;
  }
}

//
// Textareas
//
textarea.form-control {
  height: auto;
  // use rows="8" to adjust the height in your markup
}

//
// Labels
//
label,
.label {
  font-family: $font-family-base;
  font-size: rem(16);
  line-height: (24/16);
  font-weight: $font-weight-semibold;
  color: $label-color;
  display: block;
  margin: 0 0 rem(5);

  a {
    font-weight: $font-weight-normal;
  }
}

//
// Select dropdown
//

select.form-control {
  appearance: none;
  border-color: $input-border-color;
  background-image: url("data:image/svg+xml,%3Csvg height='10' viewBox='0 0 18 10' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m237 20h1l8 8 8-8h1v1l-9 9-9-9z' fill='%23b0b0b0' fill-rule='evenodd' transform='translate(-237 -20)'/%3E%3C/svg%3E");background-image: url("data:image/svg+xml,%3Csvg height='10' viewBox='0 0 18 10' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m237 20h1l8 8 8-8h1v1l-9 9-9-9z' fill='#{hex-encode($gray-border)}' fill-rule='evenodd' transform='translate(-237 -20)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 18px 10px;
  background-position: calc(100% - 16px) 50%;
  padding-right: 40px;
  vertical-align: middle;

  &.placeholder {
    color: $input-placeholder-color;
  }

  &::-ms-expand {
    display: none;
  }
}

//
// Search box
//

.input-group {
  position: relative;

  &--search {
    button {
      @include button-reset();
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 50px;
      color: $gray-medium;
      cursor: pointer;

      .icon {
        width: 24px;
        height: 24px;
        position: relative;
        top: 2px;
      }
    }
  }
}

//
// Row spacing
//

.form-group {
  margin: 0 0 $line-space;
}
