//
// Feature cards in carousels
//

.card--feature {
  position: relative;
  height: 360px;
  border: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;

  .card__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;

    img {
      object-fit: cover;
      font-family: 'object-fit: cover; object-position: center';
      object-position: 50% 50%;
      width: 100%;
      height: 100%;
      display: block;
      transition: $transition-link;
    }
  }

  .card__body,
  .card__header,
  .card__footer,
  .card__img-inline {
    width: 100%;
    position: relative;
    z-index: 2;
    padding: rem(20);

    @include breakpoint(lg) {
      padding: rem(24);
    }

    > *:last-child {
      margin-bottom: 0;
    }

    > * {
      max-width: 485px;
    }
  }

  .card__footer {
    align-self: flex-end;
  }

  .card__img-inline {
    padding-top: 0;
    margin-top: -#{rem(11)};

    img {
      display: block;
      max-width: 100%;
    }

    @include breakpoint(lg) {
      margin-top: -#{rem(34)};
    }
  }

  h2, .h2, h3, .h3 {
    padding-right: rem(16);
  }

  &.bg-dark,
  &.bg-red {
    h2, .h2, h3, .h3, .h4-h3, p, a, span {
      color: $white;
    }
  }

  &.bg-light {
    h2, .h2, h3, .h3, .h4-h3, p, a, span {
      color: $black;
    }

    .is-eyebrow {
      color: $gray-dark;
    }
  }

  // When the card is a link
  &:hover,
  &:active {

    &.bg-dark,
    &.bg-red,
    &.bg-light {
      h2, .h2, h3, .h3, .h4-h3, p, a, span {
        color: $white;
      }
    }

    .card__img img {
      filter: grayscale(100%) brightness(0.3);
    }
  }

  @include breakpoint(lg) {
    height: 480px;
  }
}
