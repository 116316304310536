//
// Grids
//
//$grid-gutter-width

.grid {
  @include make-row();
  @include row-margins();
  margin-top: -$line-space;
  position: relative;

  @include breakpoint(lg) {
    margin-top: -$line-space-2x;
  }
}

.grid__col {
  @include make-column();
  @include column-padding();
  margin-top: $line-space;

  @include breakpoint(lg) {
    margin-top: $line-space-2x;
  }
}

.js .grid[data-masonry],
.grid.is-masonry-style {
  @include breakpoint(md) {
    margin-top: -$grid-gutter-width-md;

    .grid__col {
      margin-top: $grid-gutter-width-md;
    }
  }

  @include breakpoint(lg) {
    margin-top: -$grid-gutter-width-lg;

    .grid__col {
      margin-top: $grid-gutter-width-lg;
    }
  }
}

.grid--tight {
  margin-top: -$line-space;

  .grid__col {
    margin-top: $line-space;
  }

  .is-grid-heading + & {
    margin-top: 0;
  }
}

.grid--2up {
  $width: percentage(6/12);

  .grid__col {
    flex-basis: $width;
    width: $width;
    max-width: $width;
  }

  @each $bp, $val in $grid-breakpoints {
    &-#{$bp} .grid__col {
      @include breakpoint(#{$bp}) {
        flex-basis: $width;
        width: $width;
        max-width: $width;
      }
    }
  }
}

.grid--3up {
  $width: percentage(4/12);

  .grid__col {
    flex-basis: $width;
    width: $width;
    max-width: $width;
  }

  @each $bp, $val in $grid-breakpoints {
    &-#{$bp} .grid__col {
      @include breakpoint(#{$bp}) {
        flex-basis: $width;
        width: $width;
        max-width: $width;
      }
    }
  }
}

.grid--4up {
  $width: percentage(3/12);

  .grid__col {
    flex-basis: $width;
    width: $width;
    max-width: $width;
  }

  @each $bp, $val in $grid-breakpoints {
    &-#{$bp} .grid__col {
      @include breakpoint(#{$bp}) {
        flex-basis: $width;
        width: $width;
        max-width: $width;
      }
    }
  }
}

.grid--5up {
  $width: percentage(2/10);

  .grid__col {
    flex-basis: $width;
    width: $width;
    max-width: $width;
  }

  @each $bp, $val in $grid-breakpoints {
    &-#{$bp} .grid__col {
      @include breakpoint(#{$bp}) {
        flex-basis: $width;
        width: $width;
        max-width: $width;
      }
    }
  }
}

.grid__col {

  @each $bp, $val in $grid-breakpoints {

    @include breakpoint(#{$bp}) {

      &.w-1of2-#{$bp} {
        $width: percentage(1/2);
        flex-basis: $width;
        max-width: $width;
      }

      &.w-1of3-#{$bp} {
        $width: percentage(1/3);
        flex-basis: $width;
        max-width: $width;
      }
    }

  }

}
