//
// Block
//

.block {
  @include make-container();
  margin-top: $line-space;

  @include breakpoint(lg) {
    margin-top: $line-space-2x;
  }
}

.block--full {
  max-width: none;
  @include container-outdent();

  .block + &,
  .section + & {
    @include breakpoint(lg) {
      margin-top: $line-space-4x;
    }
  }
}

.block .block:first-child {
  margin-top: 0;
}

.block.is-block-flush {
  margin-top: 0;

  & + .block {
    margin-top: 0 !important;
  }
}


//
// Block types
//

@import "blocks/block-divider";
@import "blocks/block-figure";
@import "blocks/block-infographic";
@import "blocks/block-investments";
@import "blocks/block-pager";
@import "blocks/block-tabs";
@import "blocks/block-text";
@import "blocks/block-section-head";
@import "blocks/block-search-head";
@import "blocks/block-search-results";
@import "blocks/block-slider";
@import "blocks/block-timeline";
