//
// Text alignment helpers
//

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

//
// Color helpers
//

.bg-red {
  background-color: $red;
}

.bg-orange {
  background-color: $orange;
}

.bg-teal {
  background-color: $teal;
}
.bg-teal-light {
  background-color: $teal-light;
}

.bg-blue {
  background-color: $blue;
}
.bg-blue-light {
  background-color: $blue-light;
}

.bg-purple {
  background-color: $purple;
}
.bg-purple-light {
  background-color: $purple-light;
}

.bg-green {
  background-color: $green;
}
.bg-green-light {
  background-color: $green-light;
}


.bg-gray-light,
.bg-gray {
  background-color: $gray-light;
}

.bg-gray-md {
  background-color: $gray-medium;
}

.bg-gray-dark {
  background-color: $gray-dark;
}

.bg-dark,
.bg-black {
  background-color: $black;
}

.bg-light,
.bg-white {
  background-color: $white;
}

.text-red {
  color: $red;
}

.text-orange {
  color: $orange;
}

.text-teal {
  color: $teal;
}
.text-teal-light {
  color: $teal-light;
}

.text-blue {
  color: $blue;
}
.text-blue-light {
  color: $blue-light;
}

.text-purple {
  color: $purple;
}
.text-purple-light {
  color: $purple-light;
}

.text-green {
  color: $green;
}
.text-green-light {
  color: $green-light;
}

.text-white,
.text-light {
  color: $white;
}

.text-gray,
.text-muted {
  color: $text-muted;
}

.text-dark {
  color: $text-color;
}

.text-black {
  color: $text-black;
}

.border-teal {
  border-color: $teal;
}
.border-teal-light {
  border-color: $teal-light;
}

.border-blue {
  border-color: $blue;
}
.border-blue-light {
  border-color: $blue-light;
}

.border-purple {
  border-color: $purple;
}
.border-purple-light {
  border-color: $purple-light;
}

.border-green {
  border-color: $green;
}
.border-green-light {
  border-color: $green-light;
}

//
// Force column break
//
.w100 {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
}


//
// Screenreaders
//
.sr-only {
  @include sr-only();
}

.sr-only-focusable {
  @include sr-only-focusable();
}

//
// Toggling visibility of list items
//

.is-visually-hidden {
  @include sr-only();
  opacity: 0;
}

.is-visually-shown {
  transition: opacity 0.3s ease;
}

.is-hidden {
  display: none !important;
}

.is-invisible {
  visibility: hidden !important;
}

.is-hidden-mobile {
  @include breakpoint-down(md) {
    display: none !important;
  }
}

//
// Transitions
//

// .fade {
//   @include transition($transition-fade);

//   &:not(.show) {
//     opacity: 0;
//   }
// }

// .collapse {
//   &:not(.show) {
//     display: none;
//   }
// }

// .collapsing {
//   position: relative;
//   height: 0;
//   overflow: hidden;
//   @include transition($transition-collapse);
// }

