//
// Grid and containers
//

@mixin make-row() {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@mixin row-margins() {
  margin-left: -$grid-gutter-width / 2;
  margin-right: -$grid-gutter-width / 2;

  @include breakpoint(md) {
    margin-left: -$grid-gutter-width-md / 2;
    margin-right: -$grid-gutter-width-md / 2;
  }

  @include breakpoint(lg) {
    margin-left: -$grid-gutter-width-lg / 2;
    margin-right: -$grid-gutter-width-lg / 2;
  }
}

@mixin make-column($width: 100%) {
  flex: 0 0 $width;
  width: $width;
  max-width: $width;
}

@mixin column-padding() {
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;

  @include breakpoint(md) {
    padding-left: $grid-gutter-width-md / 2;
    padding-right: $grid-gutter-width-md / 2;
  }

  @include breakpoint(lg) {
    padding-left: $grid-gutter-width-lg / 2;
    padding-right: $grid-gutter-width-lg / 2;
  }
}

@mixin make-container() {
  max-width: $container-max-width;
  margin-left: auto;
  margin-right: auto;
}

@mixin container-reset() {
  max-width: none;
  margin-left: 0;
  margin-right: 0;
}

@mixin container-padding() {
  padding-left: $container-padding-xs;
  padding-right: $container-padding-xs;

  @include breakpoint(md) {
    padding-left: $container-padding-md;
    padding-right: $container-padding-md;
  }

  @include breakpoint(lg) {
    padding-left: $container-padding-lg;
    padding-right: $container-padding-lg;
  }
}

@mixin container-outdent() {
  margin-left: -$container-padding-xs;
  margin-right: -$container-padding-xs;

  @include breakpoint(md) {
    margin-left: -$container-padding-md;
    margin-right: -$container-padding-md;
  }

  @include breakpoint(lg) {
    margin-left: -$container-padding-lg;
    margin-right: -$container-padding-lg;
  }
}
