//
// Logo cards
//

.card--logo {
  display: block;
  border: 0;
  color: $text-muted;

  img {
    display: block;
    transition: $transition-link;
  }

  &.is-logo-small img {
    @include breakpoint(lg) {
      max-height: 20px;
      width: auto;
    }
  }

  &.is-logo-small.is-logo-stacked img {
    @include breakpoint(lg) {
      max-height: 27px;
      width: auto;
    }
  }

  &:not(.is-logo-small) img.is-png {
    height: 28px; // 30 looked too big
    width: auto;
  }

  span {
    display: block;
    margin-top: rem(8);
    color: inherit;
  }

  // When the card is a link

  &:hover,
  &:active {
    color: $text-muted;

    img {
      opacity: 0.5;
    }
  }
}


//
// Logo cards in grids
//

.grid--logos {

  .grid__col {
    min-height: rem(50);
  }

  // Adjustments

  .tabs__pane > & {
    @include breakpoint(lg) {
      padding-top: rem(48);
    }
  }
}




//
// Logo cards in list (tighter grouped listing with state/location header)
//

.logos-list {

  // Targeting max breakpoints to fix IE flexbox issues

  @include breakpoint-down(lg) {
    @include make-row();
    @include row-margins();
  }

  h3 {
    @include breakpoint-down(lg) {
      @include make-column(100%);
      @include column-padding();
      margin-bottom: -14px;
    }
  }

  .card--logo {
    margin-top: rem(16);

    @include breakpoint-down(lg) {
      @include make-column(percentage(4/12));
      @include column-padding();
    }

    @include breakpoint-down(sm) {
      @include make-column(percentage(6/12));
      @include column-padding();
    }


  }

  .section__content  & {
    @include breakpoint(lg) {
      margin-top: rem(9); // align baseline with section left section heading
    }
  }

  .logos-list + & {
    margin-top: $line-space;

    @include breakpoint(lg) {
      margin-top: $line-space-2x;
    }
  }
}
