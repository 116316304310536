//
// Slider base styles
//

.slider {
  .slider__slide {
    width: 100%;
    margin: 0 4px 0;
  }

  .flickity-viewport {
    transition: height 0.2s;
  }

  .flickity-button {
    background: none;
    width: 20px;
    height: 100%;
    border-radius: 0;
    padding: 0;
  }

  .flickity-button[disabled] {
    display: none;
  }

  .flickity-prev-next-button.previous {
    left: -20px;
  }

  .flickity-prev-next-button.next {
    right: -20px;
  }

  .flickity-button-icon {
    background: none;
    stroke: $red;
    stroke-width: 6;
    fill: none;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translate3d(-50%, -50%, 0);
    transition: $transition-link;
  }

  .flickity-button:hover .flickity-button-icon {
    stroke: $black;
  }

  .flickity-page-dots {
    text-align: left;
    bottom: -21px;
  }

  .flickity-page-dots .dot {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background: $gray-dark;

    &.is-selected,
    &:hover,
    &:active {
      background: $red;
      opacity: 1;
    }
  }

  @include breakpoint(md) {
    .flickity-button {
      width: 38px;
    }

    .flickity-prev-next-button.previous {
      left: -38px;
    }

    .flickity-prev-next-button.next {
      right: -38px;
    }

    .flickity-button-icon {
      stroke-width: 3;
      width: 38px;
      height: 38px;
    }
  }

  @include breakpoint(lg) {
    .flickity-prev-next-button.previous {
      left: -55px;
    }

    .flickity-prev-next-button.next {
      right: -55px;
    }

    .flickity-page-dots {
      bottom: -36px;
    }

    .flickity-page-dots .dot {
      width: 8px;
      height: 8px;
      margin: 0 2px;
    }
  }
}

//
// Featured cards carousel
//


.block--feature-slider {
  overflow: hidden;

  // right side full-bleed only
  .feature-slider__inner {
    width: auto;
    max-width: 100%;
    margin-left: $container-padding-xs;

    @include breakpoint(md) {
      margin-left: $container-padding-md;
    }

    @include breakpoint(lg) {
      margin-left: $container-padding-lg;
    }

    //
    // $container-max-width + ($container-padding * 2) = 1440
    // 1280 + (80 * 2) = 1440
    // 1280 / 2 + 4px (slide mr) = 644
    //
    @media (min-width: 1440px) {
      margin-left: calc(50vw - 644px);
    }
  }

  // Nested within a section with a left sidebar
  // 1280 + 55 (ml) + 80 (mr) = 1415
  // 640 - 400 (sidebar) - 40(gutter) - (4px slide mr) = 196
  // 196 is off so using 193 but I'm not sure why...
  .section--sidebar-left & {
    margin-left: -20px;

    @include breakpoint(md) {
      margin-left: -40px;
    }

    @include breakpoint(lg) {
      margin-left: -80px;
    }

    .feature-slider__inner {

      .card--perspective  {
        height: 100%;
        height: 360px;

        @include breakpoint(lg) {
          height: 480px;
        }
      }
    }

    @media (min-width: 1415px) {
      margin-left: -55px; // 55px is the space for the prev button positioning
      position: relative;
      width: calc(50vw + 193px + 55px);

      //margin-right: 0;
      .feature-slider__inner {
        width: calc(100% - 55px);
        max-width: 100vw;
        margin-left: 55px;
      }
    }
  }

  // slider controls
  .slider {

    .slider__slide {
      width: 335px;
      margin: 0 5px 0 0;
      max-width: 90%;
      transition: filter 0.3s ease;

      @include breakpoint(lg) {
        width: 400px;
      }
    }

    .card__body {
      transition: opacity 0.3s ease
    }

    .flickity-page-dots {
      display: none;
    }

    .flickity-prev-next-button.next {
      right: 10px;

      .flickity-button-icon {
        stroke: $white;
      }

      &:hover, &:active {
        .flickity-button-icon {
          stroke: $white;
          opacity: 0.5;
        }
      }
    }

    &.flickity-enabled  .slider__slide {
      &:not(.is-selected) {
        filter: grayscale(100%) brightness(0.3);
        .card__body {
          opacity: 0;
        }
      }

      &:not(.is-selected):hover .card__img img {
        filter: none;
      }
    }
  }
}




//
// Home page hero carousel
//

.hero--home .slider {

  .slider__slide {
    width: 100%;
    margin: 0;
  }

  .flickity-prev-next-button.previous {
    left: 20px;
  }

  .flickity-prev-next-button.next {
    right: 20px;
  }

  .flickity-button-icon {
    stroke: $white;
  }

  .flickity-button:hover .flickity-button-icon {
    stroke: $white;
    opacity: 0.5;
  }

  .flickity-page-dots {
    // match container alignment
    @include make-container();
    @include container-padding();
    left: 50%;
    transform: translateX(-50%);
    max-width: $container-max-width + ($container-padding-lg * 2);
    // position
    bottom: -23px;

    @include breakpoint(lg) {
      bottom: -22px;
    }
  }

  @include breakpoint-down(lg) {
    .flickity-button {
      display: none;
    }
  }

  @include breakpoint-down(md) {
    .flickity-page-dots {
      display: block;
    }
  }
}
