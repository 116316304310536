@mixin breakpoint($min) {
  $bp: map-get($grid-breakpoints, $min);

  @if $bp {
    $min: $bp;
  }

  @media (min-width: $bp) {
      @content;
  }
}

@mixin breakpoint-down($max) {
  $bp: map-get($grid-breakpoints, $max);

  @if $bp {
    $max: $bp - 1px;
  }

  @media (max-width: $max) {
    @content;
  }
}

@mixin breakpoint-only($min, $max) {
  $bpmin: map-get($grid-breakpoints, $min);
  $bpmax: map-get($grid-breakpoints, $max);

  @if $bpmin {
    $min: $bpmin;
  }

  @if $bpmax {
    $max: $bpmax - 1px;
  }

  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}
