//
// Filters UI for search bars
//

.filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  background-color: $gray-light;
  padding: rem(20);

  @include breakpoint(md) {
    padding: $line-space;
  }

  .filters__heading {
    // nothing yet
  }

  // mobile collapsed button with icons
  .ui-icons {
    display: flex;
    margin: -2px -4px -6px -4px;
    position: relative;

    &::before {
      content: "";
      height: 25px;
      width: 1px;
      border-left: 1px solid $gray-rule;
      position: absolute;
      top: 0;
      left: 50%;
    }

    .icon-search {
      margin-right: 4px;
    }

    .icon-filter {
      margin-left: 4px;
    }

    @include breakpoint(md) {
      display: none;
    }
  }

  // desktop button with visible text
  .ui-label {
    display: none;

    @include breakpoint(md) {
      display: inline;
    }
  }

  // filters container
  .filters__content {
    margin-left: auto; // float right
    position: relative;
    order: 2;

    @include breakpoint(md) {
      order: 3;
    }
  }

  // filters dropdown box
  .filters__panel {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: -1px;
    padding: rem(20);
    background: $white;
    border: 1px solid $gray-dark;
    box-shadow: 0 6px 12px rgba(0,0,0,0.2);
    width: 295px;
    max-width: calc(100vw - 80px);
    z-index: ($z-index-dropdown - 1);

    @include breakpoint(md) {
      padding: $line-space-1-5x $line-space;
      width: 400px;
    }

    .form-group:last-child {
      margin-bottom: 0;
    }
  }

  .filters__toggle.is-active {
    background: $white;
    color: $gray-dark;
    border-color: $gray-dark $gray-dark $white $gray-dark;
    position: relative;
    z-index: $z-index-dropdown;
    transition: all 0s;
  }

  .filters__toggle.is-active + .filters__panel {
    display: block;
  }

  // selected filter labels

  .filters__selected {
    order: 3;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: $line-space;
    margin-bottom: -6px;
    padding-top: calc(24px - 6px);
    border-top: 1px solid $gray-rule;

    .btn {

      margin: rem(6) rem(12) rem(6) 0;
      text-align: left;
      padding-right: 26px;
      position: relative;

      .icon {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        margin: 0;
      }
    }

    @include breakpoint(md) {
      order: 2;
      width: auto;
      flex: 1;
      flex-wrap: wrap;
      max-width: auto;
      margin-left: $line-space;
      margin-right: $line-space;
      margin-top: -6px;
      margin-bottom: -6px;
      padding-top: 0;
      border-top: 0;
    }
  }
}

// Make sure the filters panel is not cut off with short results.
// Add to a parent div.
.is-filter-min-height {
  min-height: 550px;

  @include breakpoint(md) {
    min-height: 680px;
  }
}
