//
//  Search block with no page header
//

.block--search-head {
  padding-top: $line-space;
  margin-bottom: $line-space-2x;

  @include breakpoint(lg) {
    padding-top: rem(114);
  }
}
